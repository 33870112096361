<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12">
          <v-autocomplete
            v-model="user"
            :items="users"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            label="社員"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row></v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";

export default {
  name: "ShiftUserAddDialog",
  mixins: [Common, Dialog, Forms, Api],

  // props: { args: Object },
  computed: {
    Title() {
      const args = this.args;
      return args.affiliationname + " 社員追加";
    }
  },
  data() {
    return {
      users: [],
      user: []
    };
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    complete() {
      console.log("complate", this.user);
      if (!this.$refs.form.validate()) return;
      if (this.args.resulttype === "0") {
        this.$close(this.user);
      } else if (this.args.resulttype === "1") {
        const result = [];
        this.user.forEach(u => {
          const filter = this.users.filter(e => e.code === u);
          result.push(filter[0]);
        });
        console.log("result", result);
        this.$close(result);
      } else {
        this.$close();
      }
    }
  },
  created() {
    console.log("created", this.args);
    const args = this.args;
    const filter = args.users.filter(e => e.refcode != args.affiliationid);
    this.users = filter;
  }
};
</script>

<style></style>
